import React from "react";
import styled from "styled-components";

import { palette } from "../../utils";

interface IInput {
  value: string
  placeholder?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  style?: {}
}

export const Input = (
  {
    value,
    placeholder,
    onChange,
    style = {}
  }: IInput) => {

  return (
    <Container
      type='text'
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      style={{ ...style }}
    />
  )
}

Input.defaultProps = {
  value: '',
  placeholder: '',
  style: {},
  onChange: undefined,
}

const Container = styled.input`
  width: 100%;
  height: 56px;
  font-size: 1.0625em;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: ${palette.text.primary};
  border: 1px solid ${palette.bg.topSide};
  padding: 14px 24px;
  background: ${palette.bg.primary};
  border-radius: 100px;

  &::placeholder {
    color: ${palette.text.primary};
    opacity: .7;
  }
`
