import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../utils";
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';

export const Header = () => {
  return (
    <Container>
      <LogoWrap><Logo/></LogoWrap>
      <Name>SnapDiet</Name>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 16px 0 16px 22px;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (min-width: ${screens.tablet}) {
    padding-left: 30px;
  }

  @media (min-width: ${screens.desktop}) {
    padding-left: 80px ;
  }
`

const LogoWrap = styled.span`
  width: 45px;
  height: 42px;

  @media (min-width: ${screens.desktop}) {
    width: 54px;
    height: 50px;
  }
`

const Name = styled.p`
  color: ${palette.text.primary};
  font-weight: 700;
  font-size: 1.25em;

  @media (min-width: ${screens.tablet}) {
    font-size: 1.125em;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 1.375em;
  }
`
