export const palette = {
  bg: {
    primary: '#fff',
    secondary: '#55C65C',
    tertiary: '#F2FAF2',
    topSide: '#E7E9ED',
    inactive: '#F5F5F7'
  },
  text: {
    primary: '#060e2d',
    secondary: '#fff',
    tertiary: '#55C65C',
    inactive: '#161C2D',
    subtitle: '#989898',
    green: '#008000'
  }
}
