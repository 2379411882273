import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../../../utils";
import { Button } from "../../../../components";
import cookImg from '../../../../assets/img/cookImg.png';

export const Subscription = () => {
  return (
    <Container>
      <Section>
        <Heading>
          <SectionName>Подписка</SectionName>
          <Title>Get access on your licensed nutritionist for the costs of the offline consultation.</Title>
          <ButtonWrap><Button>Get started</Button></ButtonWrap>
        </Heading>

        <CardsBlock>
          <Card>
            <BiscuitImg src='/assets/biscuitImg.png' alt='biscuit'/>

            <Price>$12</Price>
            <Period>1 месяц</Period>
            <CardDescription>With lots of unique blocks, you can easily build a page without.</CardDescription>
            <CardButton>3 дня бесплатно</CardButton>
          </Card>

          <ActiveCard>
            <CookImg src={cookImg} alt='cook'/>

            <PriceBlock>
              <ActivePrice>$22</ActivePrice>
              <PriceSubtitle>сохраните $12</PriceSubtitle>
            </PriceBlock>

            <ActivePeriod>3 месяца</ActivePeriod>
            <ActiveDescription>With lots of unique blocks, you can easily build a page without.</ActiveDescription>

            <ActiveCardButton>3 дня бесплатно</ActiveCardButton>
          </ActiveCard>
        </CardsBlock>
      </Section>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Section = styled.div`
  padding: 56px 16px;
  display: flex;
  flex-direction: column;
  gap: 56px;

  @media (min-width: ${screens.tablet}) {
    padding: 60px 24px 80px;
    gap: 48px;
  }

  @media (min-width: ${screens.desktop}) {
    padding: 120px 0 160px;
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
    width: 1440px;
    margin: 0 auto;
  }
`

const Heading = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screens.desktop}) {
    width: 588px;
  }
`

const SectionName = styled.p`
  color: ${palette.text.tertiary};
  font-weight: 500;
  font-size: 0.875em;
  line-height: 18.23px;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: .16em;

  @media (min-width: ${screens.desktop}) {
    margin-bottom: 12px;
  }
`

const Title = styled.h2`
  color: ${palette.text.primary};
  font-weight: 700;
  font-size: 2em;
  line-height: 40px;
  letter-spacing: -0.018em;
  margin-bottom: 20px;

  @media (min-width: ${screens.tablet}) {
    font-size: 2.25em;
    line-height: 48px;
    margin-bottom: 0;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 3em;
    line-height: 60px;
    margin-bottom: 28px;
  }
`

const ButtonWrap = styled.div`
  width: 158px;

  @media (min-width: ${screens.tablet}) {
    display: none;
  }

  @media (min-width: ${screens.desktop}) {
    display: block;
  }
`

const CardsBlock = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  row-gap: 32px;

  @media (min-width: ${screens.tablet}) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0;
    column-gap: 20px;
  }

  @media (min-width: ${screens.desktop}) {
    width: 710px;
    gap: 20px;
  }
`

const Card = styled.div`
  background: ${palette.bg.inactive};
  border-radius: 16px;
  padding: 92px 18px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${screens.tablet}) {
    padding: 132px 22px 32px;
  }
`

const BiscuitImg = styled.img`
  width: 96px;
  position: absolute;
  top: -32px;

  @media (min-width: ${screens.tablet}) {
    width: 126px;
  }
`

const CookImg = styled.img`
  width: 110px;
  position: absolute;
  top: -44px;

  @media (min-width: ${screens.tablet}) {
    width: 140px;
  }
`

const Price = styled.p`
  color: ${palette.text.primary};
  font-size: 3em;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: -1.8px;
  margin-bottom: 8px;

  @media (min-width: ${screens.tablet}) {
    margin-bottom: 4px;
  }
`

const Period = styled.p`
  color: ${palette.text.primary};
  font-size: 1.125em;
  line-height: 29px;
  letter-spacing: -0.2px;
  opacity: .7;
  margin-bottom: 16px;

  @media (min-width: ${screens.tablet}) {
    margin-bottom: 38px;
  }
`

const CardDescription = styled.p`
  color: ${palette.text.primary};
  font-size: 1.0625em;
  line-height: 29px;
  letter-spacing: -0.2px;
  margin-bottom: 30px;
  text-align: center;

  @media (min-width: ${screens.tablet}) {
    margin-bottom: 40px;
  }
`

const CardButton = styled.button`
  background: rgba(22, 28, 45, 0.08);
  border: none;
  border-radius: 100px;
  padding: 6px 12px;
  color: rgba(22, 28, 45, 0.7);
  font-weight: 500;
  font-size: 0.8125em;
  line-height: 17px;
  letter-spacing: 1.63px;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    transition: .3s;
    opacity: .7;
  }
`

const ActiveCard = styled(Card)`
  background-image: linear-gradient(90deg, #EC008C 0%, #FC6767 100%);
`

const PriceBlock = styled.div`
  position: relative;
`

const ActivePrice = styled(Price)`
  color: ${palette.text.secondary};
`

const PriceSubtitle = styled.span`
  color: ${palette.text.secondary};
  font-weight: 500;
  font-size: 1.125em;
  line-height: 29px;
  letter-spacing: -0.2px;
  position: absolute;
  right: -74px;
  top: 0;
`

const ActivePeriod = styled(Period)`
  color: ${palette.text.secondary};
  opacity: 100%;
`

const ActiveDescription = styled(CardDescription)`
  color: ${palette.text.secondary};
`

const ActiveCardButton = styled(CardButton)`
  background: rgba(255, 255, 255, .2);
  color: ${palette.text.secondary};
`
