import React from "react";
import styled from "styled-components";

import { palette, screens } from "../../utils";

export const Privacy = () => {
  return (
    <Container>
      <Title>PRIVACY POLICY</Title>

      <Section>
        <Subtitle>INTRODUCTION</Subtitle>

        <Text>
          This Privacy Policy explains how Pratt Software Inc (“Company” or “we”) collects, uses,
          shares, and otherwise processes your personal data and your rights regarding your personal
          data.
        </Text>
        <Text>
          We provide you with our website snapdiet.app and the SnapDiet mobile application
          (together the “Service”). When you use the Service or interact with us in any way (for
          example, by communicating via email), you may provide us with your personal data. This
          Privacy Policy explains how we handle and protect your personal data.
        </Text>
        <Descript>
          “Personal data” means information that relates to, describes, or identifies you. This
          could include your name, date of birth, or email.
        </Descript>
        <Descript>
          This Privacy Policy applies to your use of the Service. If you access or use the Service,
          you agree with and accept this Privacy Policy.
        </Descript>
      </Section>

      <Section>
        <SectionTitle>INFORMATION WE MAY COLLECT</SectionTitle>
        <Text>
          Information You Provide to Us When you use or access the Service, create an account, send
          a message to us, or participate in activities on the Service, you may input personal data
          into the Service.
        </Text>
        <Descript>
          We collect the following categories of data that you voluntarily provide to us:
        </Descript>
        <List>
          <li>
            Profile information, such as full name, email, credit card information, billing name,
            and billing address.
          </li>
          <li>
            Third-party-service username and password used to sign in to the Service or information
            contained in the communications that you send to us by email, mail, or in any other
            form.
          </li>
          <li>
            Information Collected from Third Parties We may receive information about you from other
            users, third-party services, public social media platforms, or public databases. For
            example, social media platforms may display your mailing address, email, or phone
            number.
          </li>
          <li>
            Information Collected Automatically Certain information is collected automatically when
            you use or visit our Service. This information does not reveal your identity (such as
            your name or contact information), but may include information about your device, its
            use, and other technical information. This information helps us maintain security,
            ensure optimization of the Service for your needs, and facilitate customer support.
          </li>
        </List>
        <Text>
          The tracking technologies we use to collect this information include cookies, web beacons,
          Flash cookies, embedded scripts, location-identifying technologies, pixels, and other
          similar technologies. For example, we use Ampliude and Meta Pixel to analyze Service usage
          patterns.
        </Text>
        <Descript>
          The tracking technologies automatically collect the following categories of data:
        </Descript>
        <List>
          <li>
            Browser data: information that your browser automatically sends when you use the
            Service, including IP address, browser type, browser language, settings, and Service
            usage patterns.
          </li>
          <li>
            Device data: information about the device you use, such as device type, model and
            manufacturer, device operating system brand and model, device hard disk drive, operating
            system version, and information regarding the location of your device.
          </li>
          <li>
            Usage data: information about your use of the Service, including patterns, time, and
            date of your use of the Service.
          </li>
          <li>
            Analytics data: general information about traffic and usage trends for the Service.
          </li>
          <li>Service Providers</li>
        </List>
        <Text>
          We may engage certain partner service providers (“Partner Service Providers”) to
          facilitate our Service, to provide the Service on our behalf, to perform Service’s related
          services or to assist us in analyzing how our Service is used.
        </Text>
        <Text>
          These Partner Service Providers have access to your Personal Data only to perform these
          tasks on our behalf and are obligated not to disclose or use it for any other purpose.
        </Text>
        <Text>
          For example, we use Stripe to collect payments from our customers. You can read more about
          how Stripe uses your Personal Data here: <Link
          href='https://stripe.com/en-gb-us/privacy'>https://stripe.com/en-gb-us/privacy</Link>.
        </Text>
        <Descript>
          By continuing to use our Service, you agree to the collection of data by the Partner
          Service Providers retained by us.
        </Descript>
      </Section>

      <Section>
        <SectionTitle>PURPOSES FOR WHICH We Use Personal Data</SectionTitle>
        <Descript>We use the personal data collected through our Service to:</Descript>
        <List>
          <li>
            provide the Service to you, create and maintain your account, verify your identity,
            process transactions, and provide customer support;
          </li>
          <li>improve the Service for you, identify activity patterns and usage trends;</li>
          <li>
            communicate with you, send you emails, including confirmations and reminders, respond to
            your requests for assistance, or report on technical issues;
          </li>
          <li>
            update you about changes to the Service, send messages about new features or promotions
            that may interest you; or
          </li>
          <li>
            maintain safety and security, detect, prevent, and respond to potential or actual
            security incidents, and protect against other illegal activities.
          </li>
        </List>
      </Section>

      <Section>
        <SectionTitle>HOW WE DISCLOSE PERSONAL DATA</SectionTitle>
        <Descript>
          Disclosure of Personal Data to Third Parties We do not sell, share, or otherwise disclose
          your personal data, but in the following circumstances, we may share your personal data
          with the following third parties:
        </Descript>
        <List>
          <li>
            Public authorities and third parties when the law or a government order requires us to
            do so or when we need to protect our rights or users’ rights, or prevent crime.
          </li>
          <li>
            Our subsidiaries, affiliates, and co-owners. We may also disclose personal data to
            operators of the Service, subsequent owners, and their advisors in connection with a
            merger, reorganization, sale of our assets, or other change of control.
          </li>
          <li>
            Service providers when they need access to your personal data to perform services for
            us, such as web hosting, debugging services, payment processing, email and productivity
            services, survey providers, and customer service providers.
          </li>
          <li>closure of Anonymous Information</li>
        </List>
        <Text>
          We may disclose anonymous information to any third party without notification. Anonymous
          information means information which does not allow the identification of an individual
          user, such as aggregated, anonymized, or de-identified information about the use of our
          services. Such information does not constitute personal data.
        </Text>
      </Section>

      <Section>
        <SectionTitle>YOUR RIGHTS TO PERSONAL DATA</SectionTitle>
        <Descript>You have the following rights to your personal data:</Descript>
        <List>
          <li>
            The right to know what data we have about you. To exercise this right, please contact us
            at <Link href="mailto:help@prattsoftware.com">help@prattsoftware.com</Link>.
          </li>
          <li>
            The right to correct, delete, or update your personal data. You can access and modify
            your personal data through your account or the settings section of the Service. If you
            need assistance, please contact us at <Link
            href="mailto:help@prattsoftware.com">help@prattsoftware.com</Link>.
            Please note that sometimes we
            may not be able to modify or delete certain information. For example, we are required by
            law to keep certain information for a defined period.
          </li>
          <li>
            The right to opt out of receiving electronic marketing communications from us. We may
            periodically send you emails and messages promoting the use of the Service. You can opt
            out (unsubscribe) of receiving these messages by following the instructions included in
            each email or message. If you have any additional questions, please contact us at{' '}
            <Link href="mailto:help@prattsoftware.com">help@prattsoftware.com</Link>.
          </li>
        </List>
      </Section>

      <Section>
        <SectionTitle>PERSONAL DATA OF CUSTOMERS FROM CALIFORNIA</SectionTitle>
        <Descript>
          If you are a California resident, you have specific rights to your personal data:
        </Descript>
        <List>
          <li>
            You can request that we delete any of your personal data we have collected. But we may
            not be required to comply with your request if it is necessary for us to maintain your
            personal information for the reasons provided under California Civil Code Section
            1798.105(d).
          </li>
          <li>You have the right to request in writing a list of:</li>
          <li>the categories of personal data we have collected in the preceding 12 months;</li>
          <li>the categories of sources from which your personal data is collected;</li>
          <li>the business or commercial purpose for collecting your personal data;</li>
          <li>
            the categories of third parties with whom we shared your personal data in the preceding
            12 months; and
          </li>
          <li>
            the specific pieces of personal data we have collected about you during the preceding 12
            months.
          </li>
          <li>
            You have the right not to be discriminated against for exercising any of the rights
            described in this section. We will not discriminate against you for exercising your
            right to know what data we have about you or the right to delete your personal data.
          </li>
        </List>
        <Text>
          If you have any questions, please contact us at{' '}
          <Link href="mailto:help@prattsoftware.com">help@prattsoftware.com</Link>. Only you may make a
          verifiable consumer request related to your personal data. To verify your identity, we may
          ask you to verify the personal data we already have on file for you. We may ask you to
          provide your name, email address, and certain other pieces of identifying information.
        </Text>
        <Descript>
          We do not sell your personal information to facilitate or enable third parties to
          advertise to you. We do not sell the personal data of minors under 16 years of age.
        </Descript>
      </Section>

      <Section>
        <SectionTitle>DATA SECURITY</SectionTitle>
        <Text>
          We have put in place security measures to protect your personal data from misuse,
          unauthorized access, disclosure, alteration, and destruction. We maintain appropriate
          administrative, technical, and physical safeguards. This includes firewalls, password
          protection, and other authentication controls. In addition, browsing mode for the Service
          is protected by encryption technology. However, security on the internet can never be 100%
          guaranteed. We will take reasonable technical and organizational precautions to protect
          your personal data.
        </Text>
      </Section>

      <Section>
        <SectionTitle>INTERNATIONAL TRANSFERS OF PERSONAL DATA</SectionTitle>
        <Text>
          We store and process information in the United States. We, our affiliates, or service
          providers can maintain facilities in any other country. The information collected about
          you could be transferred and processed in any of these facilities. Laws governing data
          collection and transfer may differ from U.S. law in the European Union and other
          countries. You agree to the transfer of personal data to any country in which we, our
          affiliates, or service providers maintain facilities.
        </Text>
      </Section>

      <Section>
        <SectionTitle>CHILDREN’S PRIVACY</SectionTitle>
        <Text>
          You must be at least 18 years of age to register with and use the Service. The Service is
          not for the use of children under 13. We do not intend to collect personal data from
          children under 13.
        </Text>
      </Section>

      <Section>
        <SectionTitle>CHANGES TO THE PRIVACY POLICY</SectionTitle>
        <Text>
          This Privacy Policy can be updated from time to time. Please check back periodically for
          the latest version of the Privacy Policy. If there are any significant changes made to the
          use of your personal data, we will notify you. Your use of the Service following any
          changes to this Privacy Policy constitutes your acceptance of any such changes.
        </Text>
      </Section>

      <Section>
        <SectionTitle>QUESTIONS OR CONCERNS?</SectionTitle>
        <Text>
          If you have any questions about this Privacy Policy, your privacy, or the protection of
          the personal data we hold about you, you can contact us at{' '}
          <Link href="mailto:help@prattsoftware.com">help@prattsoftware.com</Link>.
        </Text>
      </Section>
    </Container>
  )
}

const Container = styled.div`
  max-width: 1120px;
  margin: 50px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 60px;
  flex: 1 0 auto;

  @media (min-width: ${screens.tablet}) {
    margin: 50px auto 0;
    width: 96%;
  }

  @media (min-width: ${screens.desktop}) {
    margin: 80px auto 0;
    gap: 80px;
  }
`
const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 1.8em;
  color: ${palette.text.primary};
  text-align: center;

  @media (min-width: ${screens.tablet}) {
    font-size: 2em;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 2.2em;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`

const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  color: ${palette.text.primary};
  margin: 10px 0;
  text-align: center;

  @media (min-width: ${screens.desktop}) {
    margin: 20px 0;
    font-size: 1.825em;
  }
`

const Subtitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em;
  color: ${palette.text.primary};
  margin: 10px 0;
  text-align: center;
`

const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 26px;
  color: ${palette.text.primary};
  opacity: .8;
  text-align: justify;
`

const Link = styled.a`
  color: ${palette.text.green};
  text-decoration: none;

  &:hover {
    filter: brightness(0.8);
    transition: 0.3s;
  }
`

const Descript = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 26px;
  color: ${palette.text.primary};
  opacity: .8;
  width: 100%;
`

const List = styled.ul`
  line-height: 32px;
  list-style-type: disc;
  margin: 10px 0;
  width: 80%;
  color: ${palette.text.primary};
  opacity: .8;
  font-weight: 500;

  @media (min-width: ${screens.tablet}) {
    width: 90%;
  }
`
