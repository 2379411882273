import React from "react";
import styled from "styled-components";

import { language, palette, screens } from "../../../../utils";
import { Button } from "../../../../components";
import BgDottedM from '../../../../assets/img/bg-dottedM.jpg'
import BgDottedL from '../../../../assets/img/bg-dottedL.jpg'

export const GetStarted = () => {
  return (
    <Container>
      <Heading>
        <SectionName id="section1">Начать</SectionName>
        <Title>Как это работает?</Title>
      </Heading>

      <StepsBlock>
        <Step>
          <StepImg fetchPriority='high' src={`/assets/ru/step1.jpg`} alt='step 1'/>

          <StepInfo>
            <SectionName>Шаг 1 из 4</SectionName>
            <StepTitle>Зарегистрируйтесь в SnapDiet.</StepTitle>
            <StepDescription>
              Создать аккаунт в SnapDiet - легко и быстро. Скачайте приложение и зарегистрируйтесь за пару секунд через Gmail, Apple ID.
            </StepDescription>
          </StepInfo>
        </Step>

        <Step>
          <StepImg fetchPriority='high' src={`/assets/ru/step2.jpg`} alt='step 2'/>

          <StepInfo>
            <SectionName>Шаг 2 из 4</SectionName>
            <StepTitle>Познакомьтесь с профессиональным нутрициологом в чате.</StepTitle>
            <StepDescription>Вы можете общаться в чате с врачом-диетологом в режиме реального времени для обсуждения плана питания и получения обратной связи по методу
              "тарелки"</StepDescription>
          </StepInfo>
        </Step>

        <Step>
          <StepImg fetchPriority='low' src={`/assets/ru/step3.jpg`} alt='step 3'/>

          <StepInfo>
            <SectionName>Шаг 3 из 4</SectionName>
            <StepTitle>Метод "тарелки"</StepTitle>
            <StepDescription>Фотографируйте и присылайте каждый прием пищи персональному нутрициологу в
              SnapDiet. Метод эффективно мотивирует идти к цели и постоянно улучшать питание.</StepDescription>
          </StepInfo>
        </Step>

        <Step>
          <StepImg fetchPriority='low' src={`/assets/ru/step4.jpg`} alt='step 4'/>

          <StepInfo>
            <SectionName>Шаг 4 из 4</SectionName>
            <StepTitle>Достигайте поставленных целей в питании и похудении.</StepTitle>
            <StepDescription>Профессиональный нутрициолог делится обратной связью по каждой "тарелке", корректируя ваш подход к питанию</StepDescription>
            <ButtonWrap><Button onClick={() => {
              document.querySelector('#section2')?.scrollIntoView({
                behavior: 'smooth'
              });
            }}>Начать</Button></ButtonWrap>
          </StepInfo>
        </Step>
      </StepsBlock>
    </Container>
  )
}

const Container = styled.div`
  padding: 0 16px 72px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${screens.tablet}) {
    padding: 60px 24px 80px;
  }

  @media (min-width: ${screens.desktop}) {
    padding: 60px 0 106px;
    width: 1440px;
    margin: 0 auto;
  }
`

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;

  @media (min-width: ${screens.tablet}) {
    margin-bottom: 32px;
  }

  @media (min-width: ${screens.desktop}) {
    gap: 12px;
    margin-bottom: 18px;
  }
`

const SectionName = styled.p`
  color: ${palette.text.tertiary};
  font-weight: 500;
  font-size: 0.875em;
  line-height: 18.23px;
  text-transform: uppercase;
  letter-spacing: .16em;
`

const Title = styled.h2`
  color: ${palette.text.primary};
  font-weight: 700;
  font-size: 2em;
  line-height: 40px;
  letter-spacing: -0.018em;

  @media (min-width: ${screens.tablet}) {
    font-size: 2.25em;
    line-height: 48px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 3em;
    line-height: 60px;
  }
`

const StepsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: ${screens.tablet}) {
    background-image: url(${BgDottedM});
    background-repeat: no-repeat;
    background-position: center;
    //background-size: 70% 100%;
    gap: 56px ;
  }

  @media (min-width: ${screens.desktop}) {
    background-image: url(${BgDottedL});
    background-position: 50% 0;
    background-size: auto 94%;
    gap: 0;
  }
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: ${screens.tablet}) {
    flex-direction: row;
    align-items: center;
    gap: 0;
    justify-content: space-between;
  }

  &:nth-child(odd) {
    @media (min-width: ${screens.tablet}) {
      flex-direction: row-reverse;
    }
  }

  @media (min-width: ${screens.desktop}) {

  }
`

const StepImg = styled.img`
  @media (min-width: ${screens.tablet}) {
    width: 352px;
  }

  @media (min-width: ${screens.desktop}) {
    width: 588px;
  }
`

const StepInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screens.tablet}) {
    width: 50%;
  }

  @media (min-width: ${screens.desktop}) {
    width: 560px;
  }
`

const StepTitle = styled.h3`
  padding: 8px 0 16px;
  color: ${palette.text.primary};
  font-weight: 700;
  font-size: 1.5em;
  line-height: 32px;
  letter-spacing: -0.8px;

  @media (min-width: ${screens.tablet}) {
    font-size: 2em;
    line-height: 40px;
  }

  @media (min-width: ${screens.desktop}) {
    font-size: 3em;
    line-height: 60px;
    padding: 28px 0;
  }
`

const StepDescription = styled.p`
  color: ${palette.text.primary};
  line-height: 24px;
  letter-spacing: -0.2px;
  opacity: .7;

  @media (min-width: ${screens.desktop}) {
    font-size: 1.125em;
    line-height: 28px;
  }
`

const ButtonWrap = styled.div`
  width: 158px;
  margin-top: 16px;

  @media (min-width: ${screens.desktop}) {
    margin-top: 28px;
  }
`
